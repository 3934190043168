<template>
<div class="PutDisplay">
  <iframe src="https://ve.mvpmeta.com/new/preview/#/?id=7125&secret=NzEyNQ=="  scrolling="no"  style="width: 100%;height: 100%;" frameborder="0" ></iframe>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.PutDisplay{
  width: 100%;
  height: 100%;
}
</style>